import "./index.css";
import MainSection from "./components/Sections/MainSection";
import AboutSection from "./components/Sections/AboutSection";
import { Box, Container } from "@chakra-ui/react";
import CurrentProjectsSection from "./components/Sections/CurrentProjectsSection";
import InternationalEventsSection from "./components/Sections/InternationalEventsSection";
import RecordSection from "./components/Sections/RecordSection";
import Footer from "./components/Footer";
import WhatCanYouSection from "./components/Sections/WhatCanYouSection";

function App() {
  return (
    <Box className={"scroller"}>
      <Container maxW={"container.page"} p={0}>
        <MainSection />
        <AboutSection />
        <CurrentProjectsSection />
        <InternationalEventsSection />
        <RecordSection />
        <WhatCanYouSection />
        <Footer />
      </Container>
    </Box>
  );
}

export default App;
