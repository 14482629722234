import { Box, Flex, Image } from "@chakra-ui/react";

const ImagePhoneSize = () => (
  <Flex w={"full"} justify={"space-between"}>
    <Box>
      <Image
        w={"110px"}
        h={"152px"}
        objectFit={"cover"}
        src={"/assets/about/img.png"}
        alt={""}
      />
    </Box>
    <Box>
      <Image
        w={"110px"}
        h={"152px"}
        objectFit={"cover"}
        src={"/assets/about/img_1.png"}
        alt={""}
      />
    </Box>
    <Box>
      <Image
        w={"110px"}
        h={"152px"}
        objectFit={"cover"}
        src={"/assets/about/img_2.png"}
        alt={""}
      />
    </Box>
  </Flex>
);

export default ImagePhoneSize;
