import { Flex, Image, Link, Text } from "@chakra-ui/react";

const text = "Get in \n touch";
const GetInTouch = () => (
  <Link href={"mailto:hello@marlygroup.com"} _hover={{ textStyle: "none" }}>
    <Flex
      w={{ base: "90px", md: "135px", xl: "169px" }}
      h={{ base: "90px", md: "135px", xl: "169px" }}
      bg={"purple.600"}
      borderRadius={"50%"}
      justify={"center"}
      align={"center"}
      color={"white"}
      direction={"column"}
      fontSize={{ base: "18px", md: "20px", xl: "24px" }}
      fontWeight={400}
      lineHeight={"93%"}
      whiteSpace={"pre"}
      cursor={"pointer"}
    >
      <Text>{text}</Text>
      <Image mt={"10px"} src={"/assets/arrow_one.svg"} alt={""} />
    </Flex>
  </Link>
);

export default GetInTouch;
