import { VStack, Flex, Image } from "@chakra-ui/react";

const ImageTableSize = () => (
  <VStack spacing={"10px"}>
    <Flex w={"full"} justify={"end"}>
      <Image w={"163px"} h={"203px"} src={"/assets/about/img.png"} alt={""} />
    </Flex>
    <Flex>
      <Image
        mr={"10px"}
        w={"129px"}
        h={"120px"}
        src={"/assets/about/img_1.png"}
        alt={""}
      />
      <Image w={"96px"} h={"134px"} src={"/assets/about/img_2.png"} alt={""} />
    </Flex>
  </VStack>
);

export default ImageTableSize;
