export const elements = [
  {
    title: "Indigo Design Award",
    description:
      "Celebrating inspirational and game-changing digital design across a host of different mediums.",
    href: "https://indigoaward.com",
    srcImage: "/assets/current/indigo.png",
  },
  {
    title: "Eco World Award",
    description:
      "Fostering and promoting environmental sustainability and stewardship through responsible design.",
    href: "https://ewa.indigoawards.com",
    srcImage: "/assets/current/ewa.png",
  },
];
