import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { elements } from "./elements";
import SliderItem from "./SliderItem";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import throttle from "lodash/throttle";

const SlideDirection = {
  NONE: "NONE",
  NEXT: "NEXT",
  PREV: "PREV",
};

const Slider = () => {
  const [slide, setSlide] = useState(0);
  const activeWidthDots = useBreakpointValue({ base: "77px", xl: "138px" });
  const widthDots = useBreakpointValue({ base: "27px", xl: "48px" });
  const heightDots = useBreakpointValue({ base: "1px", md: "2px", xl: "3px" });
  const ref = useRef();

  const setNextSlide = (direction) => {
    const count = elements.length;
    let newIndex = 0;

    if (direction === SlideDirection.NONE) {
      return null;
    }
    if (direction === SlideDirection.NEXT) {
      if (slide < count - 1) {
        newIndex = slide + 1;
      } else {
        newIndex = 0;
      }
    }
    if (direction === SlideDirection.PREV) {
      if (slide === 0) {
        newIndex = count - 1;
      } else {
        newIndex = slide - 1;
      }
    }

    setSlide(newIndex);
  };

  const setNextSlideThrottled = throttle(setNextSlide, 1350, {
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    const el = ref.current;
    if (el) {
      const onWheel = (e) => e.preventDefault();
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);

  const onWheel = (e) => {
    const { deltaY } = e;
    let direction = SlideDirection.NONE;
    if (deltaY < 0) {
      direction = SlideDirection.PREV;
    }
    if (deltaY > 0) {
      direction = SlideDirection.NEXT;
    }

    setNextSlideThrottled(direction);
  };

  return (
    <Box w={"full"}>
      <Flex direction={{ base: "column-reverse", md: "column" }}>
        <Box ref={ref} onWheel={onWheel}>
          <AnimatePresence initial={false} exitBeforeEnter>
            <motion.div
              key={elements[slide].title}
              initial={{ x: 1280, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -1280, opacity: 0 }}
              transition={{
                opacity: 1,
              }}
            >
              <SliderItem {...elements[slide]} />
            </motion.div>
          </AnimatePresence>
        </Box>

        <AnimatePresence initial={false} exitBeforeEnter>
          <Flex mt={{ base: "40px", md: "20px", xl: "40px" }}>
            {[0, 1, 2].map((key) => (
              <Box
                key={key}
                h={{ base: "20px", md: "50px" }}
                cursor={"pointer"}
                onClick={() => setSlide(key)}
              >
                <motion.div
                  key={key}
                  style={{
                    width: slide === key ? activeWidthDots : widthDots,
                    height: heightDots,
                    marginRight: "20px",
                    backgroundColor: slide === key ? "#613FE5" : "white",
                  }}
                  initial={{
                    width: slide === key ? activeWidthDots : widthDots,
                  }}
                  animate={{
                    width: slide === key ? activeWidthDots : widthDots,
                  }}
                  exit={{
                    width: slide !== key ? activeWidthDots : widthDots,
                  }}
                ></motion.div>
              </Box>
            ))}
          </Flex>
        </AnimatePresence>
      </Flex>
    </Box>
  );
};

export default Slider;
