import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: "Josefin Sans, sans-serif",
  },
  sizes: {
    container: {
      sm: "360px",
      md: "668px",
      xl: "1280px",
      page: "1441px",
    },
  },
  colors: {
    purple: {
      600: "#613FE5",
    },
  },
});

export default theme;
