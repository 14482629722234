export const elements = [
  {
    srcImage: "/assets/slider/1.png",
    title: "Plan and execute a world-class event",
    description:
      "Expect us to plan and execute a world-class event in the city of your choice, championing your mission and vision. From Malaga to Istanbul to Tokyo, we’ve proven time and time again that we’re up to the challenge.",
  },
  {
    srcImage: "/assets/slider/img.png",
    title: "Proprietary, scalable platform",
    description:
      "We built our proprietary, scalable platform with competitions mind. Events and competitions can easily get disorganized and overwhelming in less capable hands, which is why ours allows rapid, organized, and accurate entry submission, so all of the data is collected in one place. We also announce judges, and winners and issue benefits right through the platform.",
  },
  {
    srcImage: "/assets/slider/img_1.png",
    title: "Building online communities",
    description:
      "While our platform works to ensure that everything \n" +
      "is moving smoothly on a technological front, we also \n" +
      "do the hard but necessary work of building online \n" +
      "communities from the ground up. These communities help spread the word about your company and event organically and can help you reap valuable social capital and clout.",
  },
];
