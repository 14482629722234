import { Box, Flex, Image, Text } from "@chakra-ui/react";

const SliderItem = ({ srcImage, title, description }) => {
  return (
    <Flex
      w={"full"}
      mt={{ base: "0", md: "25px", xl: "80px" }}
      align={"center"}
      direction={{ base: "column", md: "row" }}
    >
      <Box
        w={{ base: "full", md: "50%" }}
        mr={{ base: "0", md: "20px", xl: "30px" }}
      >
        <Image
          w={{ base: "full", md: "314px", xl: "564px" }}
          h={{ base: "176px", md: "216px", xl: "366px" }}
          objectFit={"cover"}
          src={srcImage}
          alt={""}
        />
      </Box>
      <Box
        w={{ base: "full", md: "50%" }}
        mt={{ base: "15px", md: 0 }}
        fontSize={{ base: "14px", md: "20px", xl: "25px" }}
        fontWeight={400}
        lineHeight={"100%"}
      >
        <Text
          fontSize={{ base: "24px", md: "36px", xl: "60px" }}
          lineHeight={"100%"}
        >
          {title}
        </Text>
        <Text mt={{ base: "10px", md: "20px", xl: "35px" }}>{description}</Text>
      </Box>
    </Flex>
  );
};

export default SliderItem;
