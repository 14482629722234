import PageContainer from "../../PageContainer";
import LayoutSection from "../../LayoutSection";
import { Box, Flex, Grid, Image, Text } from "@chakra-ui/react";
import { elements } from "./elements";
import ReactPlayer from "react-player";

const InternationalEventsSection = () => (
  <Box bg={"black"} color={"white"} id={"events"}>
    <PageContainer>
      <LayoutSection
        title={"International events"}
        opacity={".8"}
        colorText={"white"}
      >
        <Text
          mt={{ base: "45px", xl: "90px" }}
          maxW={{ base: "full", md: "575px", xl: "736px" }}
          fontSize={{ base: "20px", md: "28px", xl: "36px" }}
          fontWeight={400}
          lineHeight={"100%"}
        >
          We plan, execute, and host award ceremonies in different cosmopolitan
          cities, drawing designers and industry professionals worldwide every
          year.
        </Text>
        <Grid
          mt={{ base: "55px", md: "120px", xl: "238px" }}
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          gridColumnGap={"60px"}
          gridRowGap={"35px"}
        >
          {elements.map(({ srcIcon, year, country, description }, key) => (
            <Flex
              key={key}
              direction={"column"}
              maxW={"285px"}
              fontSize={{ base: "20px", md: "30px", xl: "45px" }}
              fontWeight={400}
              lineHeight={"93%"}
            >
              <Image
                w={{ base: "40px", md: "60px", xl: "90px" }}
                h={{ base: "65px", md: "105px", xl: "94px" }}
                src={srcIcon}
                alt={""}
              />
              <Text mt={{ base: "15px", xl: "23px" }}>{year}</Text>
              <Text
                mt={{ base: "5px", md: "12px" }}
                whiteSpace={"pre"}
                flex={{ base: "0 0 10px", md: "0 0 37px", xl: "0 0 90px" }}
              >
                {country}
              </Text>
              <Text
                mt={{ base: "15px", md: "30px", xl: "35px" }}
                fontSize={{ base: "14px", md: "20px", xl: "25px" }}
                maxW={{ base: "264px", md: "269px", xl: "auto" }}
                fontWeight={300}
                lineHeight={"100%"}
              >
                {description}
              </Text>
            </Flex>
          ))}
        </Grid>
        <Flex
          w={"full"}
          mt={{ base: "55px", md: "120px", xl: "238px" }}
          direction={{ base: "column", xl: "row" }}
          justify={"space-between"}
          align={{ base: "start", xl: "center" }}
        >
          <Flex align={"center"}>
            <Text
              fontSize={{ base: "14px", md: "20px", xl: "25px" }}
              fontWeight={400}
              lineHeight={"100%"}
              mr={"35px"}
            >
              Watch the video from our last event
            </Text>
            <Image
              display={{ base: "none", xl: "block" }}
              src={"/assets/events/arrow.svg"}
              alt={""}
            />
          </Flex>
          <Box
            mt={{ base: "15px", md: "25px", xl: "none" }}
            w={{ base: "full", xl: "704px" }}
            h={{ base: "200px", md: "349px" }}
          >
            <ReactPlayer
              width={"100%"}
              height={"100%"}
              controls={true}
              url={"https://www.youtube.com/watch?v=zlIm9tqnPQ4"}
            />
          </Box>
        </Flex>
      </LayoutSection>
    </PageContainer>
  </Box>
);

export default InternationalEventsSection;
