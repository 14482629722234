import { Box, Text } from "@chakra-ui/react";
import PageContainer from "../../PageContainer";
import LayoutSection from "../../LayoutSection";
import LaptopPage from "./components/LaptopPage";
import MobilePage from "./components/MobilePage";

const RecordSection = () => (
  <Box
    id={"success"}
    bgImage={{
      base: "/assets/record/record-bg-sm.svg",
      md: "/assets/record/record-bg-md.svg",
      xl: "/assets/record/record-bg-xl.svg",
    }}
    bgRepeat={"no-repeat"}
  >
    <PageContainer>
      <LayoutSection
        title={"Proven track record of success"}
        withLine={false}
        opacity={1}
        colorText={"black"}
      >
        <Box display={{ base: "none", xl: "block" }} mt={"60px"}>
          <LaptopPage
            firstText={
              "We specialize in running competitions from scratch, organizing events in any space or sector, and building and nurturing online communities. "
            }
            secondText={
              "This foundational support allows you to tailor the event to fit your mission and goal, all while having the basic nuts and bolts firmly in place to help every step of the way."
            }
            srcImage={"/assets/record/img.png"}
            mainText={
              <Text>
                <Text as={"span"} color={"purple.600"}>
                  In short
                </Text>
                , you just need to communicate your vision, and we will do the
                rest.
              </Text>
            }
          />
        </Box>
        <Box
          display={{ base: "block", xl: "none" }}
          mt={{ base: "35px", md: "40px" }}
        >
          <MobilePage
            firstText={
              "We specialize in running competitions from scratch, organizing events in any space or sector, and building and nurturing online communities. "
            }
            secondText={
              "This foundational support allows you to tailor the event to fit your mission and goal, all while having the basic nuts and bolts firmly in place to help every step of the way."
            }
            srcImage={"/assets/record/img.png"}
            mainText={
              <Text>
                <Text as={"span"} color={"purple.600"}>
                  In short
                </Text>
                , you just need to communicate your vision, and we will do the
                rest.
              </Text>
            }
          />
        </Box>
      </LayoutSection>
    </PageContainer>
  </Box>
);

export default RecordSection;
