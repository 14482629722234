import { Box, Flex, Image, Text } from "@chakra-ui/react";
import GetInTouch from "../../../GetInTouch";

const LaptopPage = ({ firstText, secondText, mainText, srcImage }) => (
  <>
    <Flex w={"full"} fontSize={"25px"} fontWeight={300} lineHeight={"100%"}>
      <Box w={"564px"}>
        <Text maxW={"537px"}>{firstText}</Text>
      </Box>
      <Text maxW={"569px"} ml={"60px"}>
        {secondText}
      </Text>
    </Flex>
    <Flex mt={"60px"}>
      <Image w={"564px"} h={"370px"} src={srcImage} alt={""} />
      <Box ml={"60px"}>
        <Box
          maxW={"635px"}
          fontSize={"60px"}
          fontWeight={400}
          lineHeight={"93%"}
        >
          {mainText}
        </Box>
        <Box mt={"33px"}>
          <GetInTouch />
        </Box>
      </Box>
    </Flex>
  </>
);

export default LaptopPage;
