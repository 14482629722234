import Navbar from "../../Navbar";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import PageContainer from "../../PageContainer";
import GetInTouch from "../../GetInTouch";

const MainSection = () => {
  return (
    <Box
      minH={{ base: "599px", md: "748px", xl: "865px" }}
      pb={{ base: "50px", md: "100px" }}
      bgImage={{
        base: "/assets/main/main-bg-sm.svg",
        md: "/assets/main/main-bg-md.svg",
        xl: "/assets/main/main-bg-xl.svg",
      }}
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
    >
      <PageContainer>
        <Navbar />
        <Text
          mt={{ base: "50px", xl: "60px" }}
          fontSize={{ base: "50px", md: "100px", xl: "150px" }}
          fontWeight={700}
          lineHeight={"94%"}
        >
          Marly Group
        </Text>
        <Box>
          <Flex
            w={"full"}
            justify={{ base: "start", xl: "end" }}
            position={"relative"}
          >
            <Box maxW={{ base: "full", md: "688px", xl: "815px" }}>
              <Text
                mt={{ base: "30px", md: "36px", xl: "56px" }}
                fontSize={{ base: "24px", md: "30px", xl: "35px" }}
                fontWeight={400}
                lineHeight={"94%"}
              >
                Full-service agency:
              </Text>
              <Flex
                mt={{ base: "25px", md: "40px", xl: "40px" }}
                justify={"space-between"}
                fontSize={{ base: "14px", md: "25px" }}
                fontWeight={400}
                lineHeight={"94%"}
              >
                <Box>
                  <Image src={"/assets/main/one.svg"} alt={""} />
                  <Text mt={{ base: "10px", md: "20px" }}>
                    organizing events worldwide
                  </Text>
                </Box>
                <Box>
                  <Image src={"/assets/main/two.svg"} alt={""} />
                  <Text mt={{ base: "10px", md: "20px" }}>
                    building digital communities
                  </Text>
                </Box>
                <Box>
                  <Image src={"/assets/main/three.svg"} alt={""} />
                  <Text mt={{ base: "10px", md: "20px" }}>
                    running international contests
                  </Text>
                </Box>
              </Flex>
              <Flex
                mt={{ base: "45px", md: "50", xl: "55px" }}
                fontSize={{ base: "14px", md: "20px", xl: "24px" }}
                fontWeight={300}
                lineHeight={"100%"}
              >
                <Flex
                  w={{ base: "250px", md: "400px", xl: "full" }}
                  direction={{ base: "column", xl: "row" }}
                >
                  <Text
                    maxW={{ base: "207px", md: "304px", xl: "371px" }}
                    mr={"23px"}
                  >
                    <Text as={"span"} color={"purple.600"}>
                      Marly Group handles all of your event and award
                      competition planning needs{" "}
                    </Text>
                    from scratch through a proprietary event-planning
                    infrastructure and globally-based team.
                  </Text>
                  <Text
                    mt={{ base: "20px", xl: 0 }}
                    maxW={{ base: "207px", md: "304px", xl: "482px" }}
                  >
                    <Text as={"span"} color={"purple.600"}>
                      Our international team of client relations, technology,
                      and events management professionals
                    </Text>{" "}
                    work seamlessly behind the scenes to bring your vision to
                    life in a professional and truly memorable way.
                  </Text>
                </Flex>
                <Box
                  position={{ base: "normal", xl: "absolute" }}
                  top={"-80px"}
                  right={"20px"}
                >
                  <GetInTouch />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </PageContainer>
    </Box>
  );
};

export default MainSection;
