import { Box, Flex, Image, Text, VStack } from "@chakra-ui/react";
import PageContainer from "../../PageContainer";
import LayoutSection from "../../LayoutSection";
import ImagePhoneSize from "./components/ImagePhoneSize";
import ImageTableSize from "./components/ImageTabletSize";
import ImageLaptopSize from "./components/ImageLaptopSize";
import { minHeightSection } from "../../../minHeightSection";

const AboutSection = () => {
  return (
    <Box minH={minHeightSection} bg={"black"} color={"white"} id={"about"}>
      <PageContainer>
        <LayoutSection title={"About"}>
          <Flex
            position={"relative"}
            justify={"space-between"}
            direction={{ base: "column", md: "row" }}
          >
            <Box w={{ base: "full", md: "50%" }}>
              <VStack
                mt={{ base: "35px", md: "40px", xl: "80px" }}
                maxW={{ base: "445px", xl: "578px" }}
                align={"start"}
                spacing={{ base: "15px", md: "25px", xl: "40px" }}
                fontSize={{ base: "14px", md: "20px", xl: "25px" }}
                fontWeight={400}
                lineHeight={"100%"}
              >
                <Text>
                  <Text as={"span"} opacity={".8"}>
                    Hong Kong-based and globally minded, we pride ourselves on
                    being
                  </Text>{" "}
                  the premier full-service agency for innovative competitions,
                  events, community-building, and client promotion.
                </Text>
                <Text>
                  <Text as={"span"} opacity={".8"}>
                    From start to finish, we are your behind-the-scenes dream
                    team;{" "}
                  </Text>
                  handling any challenge or last-minute emergency so you can sit
                  back and enjoy the flawless results.
                </Text>
                <Text>
                  From Tokyo to Malaga to Istanbul, we continue to set the bar
                  high with lavish events in world-class cities.
                </Text>
              </VStack>
            </Box>

            <Box
              display={{ base: "none", xl: "block" }}
              position={"absolute"}
              top={"-31px"}
              right={0}
            >
              <ImageLaptopSize />
            </Box>
            <Box
              mt={"60px"}
              display={{ base: "none", md: "block", xl: "none" }}
            >
              <ImageTableSize />
            </Box>
            <Box mt={"25px"} display={{ base: "block", md: "none" }}>
              <ImagePhoneSize />
            </Box>
            <Box
              display={{ base: "none", md: "block" }}
              position={"absolute"}
              bottom={{ md: "-30px", xl: "-70px" }}
              right={{ md: "none", xl: "360px" }}
            >
              <Image src={"/assets/about/arrow.svg"} alt={""} />
            </Box>
          </Flex>
        </LayoutSection>
      </PageContainer>
    </Box>
  );
};

export default AboutSection;
