import { Box, Text } from "@chakra-ui/react";
import PageContainer from "../../PageContainer";
import LayoutSection from "../../LayoutSection";
import Slider from "./components/Slider";

const WhatCanYouSection = () => (
  <Box bg={"black"} color={"white"} id={"expect"}>
    <PageContainer>
      <LayoutSection
        title={"What can you expect"}
        colorText={"white"}
        opacity={".8"}
      >
        <Text
          mt={{ base: "35px", md: "64px", xl: "60px" }}
          maxW={{ base: "full", md: "340px", xl: "738px" }}
          fontSize={{ base: "14px", md: "20px", xl: "25px" }}
          fontWeight={400}
          lineHeight={"100%"}
        >
          We hand-selected our international, globally-minded team specifically
          to address the needs of events and competitions on a worldwide scale.
        </Text>
        <Box>
          <Slider />
        </Box>
      </LayoutSection>
    </PageContainer>
  </Box>
);

export default WhatCanYouSection;
