import { Box, Flex, Image, Text } from "@chakra-ui/react";
import GetInTouch from "../../../GetInTouch";

const MobilePage = ({ firstText, secondText, mainText, srcImage }) => (
  <>
    <Flex direction={{ base: "column", md: "row" }} justify={"space-between"}>
      <Box
        maxW={{ base: "348px", md: "307px" }}
        fontSize={{ base: "14px", md: "20px" }}
        fontWeight={300}
        lineHeight={"100%"}
      >
        <Text>{firstText}</Text>
        <Text mt={{ base: "15px", md: "25px" }}>{secondText}</Text>
      </Box>
      <Box mt={{ base: "25px", md: 0 }}>
        <Image
          w={{ base: "full", md: "284px" }}
          h={"186px"}
          mr={"30px"}
          src={srcImage}
          alt={""}
          objectFit={"cover"}
        />
      </Box>
    </Flex>
    <Flex
      w={"full"}
      mt={{ base: "43px", md: "73px" }}
      justify={"space-between"}
      direction={{ base: "row-reverse", md: "row" }}
    >
      <Box>
        <GetInTouch />
      </Box>
      <Box
        maxW={{ base: "221px", md: "314px" }}
        fontSize={{ base: "24px", md: "36px" }}
        fontWeight={400}
        lineHeight={"93%"}
      >
        {mainText}
      </Box>
    </Flex>
  </>
);

export default MobilePage;
