export const elements = [
  {
    srcIcon: "/assets/events/japan.svg",
    year: "2018",
    country: "Tokyo, Japan",
    description: `Wonderfully diverse 
                  and in an aesthetic class 
                  of its own, Tokyo, Japan, 
                  was the ideal location 
                  for our first Indigo Design Award gala.`,
  },
  {
    srcIcon: "/assets/events/spain.svg",
    year: "2019",
    country: "Malaga, Spain",
    description: `A creative, musical, 
                  and design hotspot, 
                  Malaga, Spain, 
                  is endlessly inspirational 
                  and was ideal 
                  for the 2019 gala.`,
  },
  {
    srcIcon: "/assets/events/turkey.svg",
    year: "2022",
    country: "Istanbul,\nTurkey",
    description: `Straddling Europe 
                  and Asia, Istanbul, 
                  Turkey, is an exciting celebration of different 
                  styles and influences, 
                  the perfect spot 
                  for our 2022 gala.`,
  },
  {
    srcIcon: "/assets/events/more.svg",
    year: "2023",
    country: "more events \nto come in",
    description: `Our team is busily working 
                  on making the 2023 award ceremony the best yet. 
                  Stay tuned for our exciting upcoming destination!`,
  },
];
