import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import PageContainer from "../../PageContainer";
import LayoutSection from "../../LayoutSection";
import { minHeightSection } from "../../../minHeightSection";
import { elements } from "./elements";

const CurrentProjectsSection = () => (
  <Box minH={minHeightSection} id={"current"}>
    <PageContainer>
      <LayoutSection
        title={"Current projects"}
        withLine={false}
        opacity={1}
        colorText={"black"}
      >
        <Flex
          w={"full"}
          justify={"space-between"}
          direction={{ base: "column", md: "row" }}
          wrap={"wrap"}
        >
          {elements.map(({ title, description, href, srcImage }, key) => (
            <Box
              key={key}
              mt={{ base: "45px", md: "50px", xl: "109px" }}
              w={{ base: "full", md: "49%" }}
            >
              <Image
                w={{ base: "full", md: "325px", xl: "625px" }}
                h={{ base: "191px", xl: "366px" }}
                src={srcImage}
                objectFit={"cover"}
                alt={""}
              />
              <Text
                mt={{ base: "20px", md: "25px", xl: "40px" }}
                fontSize={{ base: "24px", md: "28px", xl: "35px" }}
                fontWeight={400}
                lineHeight={"93%"}
                color={"purple.600"}
              >
                {title}
              </Text>
              <Text
                mt={{ base: "15px", md: "10px", xl: "17px" }}
                maxW={{ base: "full", md: "355px", xl: "612px" }}
                fontSize={{ base: "14px", md: "20px", xl: "25px" }}
                fontWeight={300}
                lineHeight={"93%"}
              >
                {description}
              </Text>
              <Flex justify={{ base: "start", xl: "end" }}>
                <Link
                  href={href}
                  mt={{ base: "15px", md: "25px", xl: "30px" }}
                  fontSize={{ base: "16px", md: "20px", xl: "25px" }}
                  fontWeight={300}
                  lineHeight={"100%"}
                  _hover={{ textStyle: "none" }}
                >
                  <Flex align={"center"}>
                    <Text mr={{ base: "12px", md: "14px", xl: "24px" }}>
                      find out more
                    </Text>
                    <Image
                      w={{ base: "9px", md: "12px", xl: "15px" }}
                      h={{ base: "9px", md: "12px", xl: "15px" }}
                      src={"/assets/current/arrow.svg"}
                      alt={""}
                    />
                  </Flex>
                </Link>
              </Flex>
            </Box>
          ))}
        </Flex>
      </LayoutSection>
    </PageContainer>
  </Box>
);

export default CurrentProjectsSection;
