import { Box, Flex } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import ModalMenu from "../ModalMenu";

const Navbar = () => {
  return (
    <Flex w={"full"} align={"end"} justify={"space-between"}>
      <Box
        maxW={{ base: "117px", md: "152px" }}
        pt={{ base: "35px", md: "50px", xl: "60px" }}
        fontSize={{ base: "20px", md: "26px" }}
        fontWeight={500}
        lineHeight={"94%"}
      >
        Marly Group Limited
      </Box>
      <Box mt={"20px"}>
        <ModalMenu>
          <HamburgerIcon color={"white"} fontSize={"33px"} />
        </ModalMenu>
      </Box>
    </Flex>
  );
};

export default Navbar;
