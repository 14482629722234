import { Box, Flex, Image } from "@chakra-ui/react";

const ImageLaptopSize = () => (
  <Box position={"relative"}>
    <Flex align={"start"}>
      <Image
        w={"298px"}
        h={"374px"}
        mr={"14px"}
        src={"/assets/about/img.png"}
        alt={""}
      />
      <Image w={"238px"} h={"220px"} src={"/assets/about/img_1.png"} alt={""} />
    </Flex>
    <Flex
      w={"full"}
      justify={"end"}
      position={"absolute"}
      bottom={"-120px"}
      right={"60px"}
    >
      <Image w={"177px"} h={"246px"} src={"/assets/about/img_2.png"} alt={""} />
    </Flex>
  </Box>
);

export default ImageLaptopSize;
