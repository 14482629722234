import {
  Box,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Scroll from "react-scroll";

const linkStyle = {
  color: "purple.600",
  // borderBottom: "1px",
  // borderColor: "purple.600",
  _hover: { testStyle: "none" },
};

const ModalMenu = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const scroll = Scroll.scroller;
  const toTag = (id) => {
    onClose();
    scroll.scrollTo(id, {
      smooth: true,
      duration: 1000,
    });
  };

  return (
    <>
      <Box cursor={"pointer"} onClick={onOpen}>
        {children}
      </Box>
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={0}
          py={{ base: "10px", md: "30px" }}
          px={{ base: "10px", md: "50px", xl: "80px" }}
        >
          <ModalBody>
            <ModalCloseButton
              outline={"none"}
              _focus={{ outline: "none" }}
              _active={{ outline: "none" }}
              _focusVisible={"none"}
            />
            <Text
              maxW={{ base: "205px", md: "400px" }}
              fontSize={{ base: "35px", md: "60px" }}
              fontWeight={500}
              lineHeight={"100%"}
            >
              Marly Group Limited
            </Text>
            <Text
              mt={{ base: "110px", md: "144px", xl: "176px" }}
              fontSize={{ base: "24px", md: "42px", xl: "50px" }}
              fontWeight={400}
              lineHeight={"100%"}
            >
              Take a look at our{" "}
              <Link {...linkStyle} onClick={() => toTag("current")}>
                current projects
              </Link>
              , learn{" "}
              <Link {...linkStyle} onClick={() => toTag("about")}>
                about us
              </Link>{" "}
              and{" "}
              <Link {...linkStyle} onClick={() => toTag("success")}>
                our success
              </Link>
              , check out our{" "}
              <Link {...linkStyle} onClick={() => toTag("events")}>
                international events
              </Link>{" "}
              and{" "}
              <Link {...linkStyle} onClick={() => toTag("expect")}>
                what you can expect
              </Link>
            </Text>
            <Link href={"mailto:hello@marlygroup.com"} {...linkStyle}>
              <Flex
                mt={{ base: "192px", md: "210", xl: "192px" }}
                align={"center"}
                fontSize={{ base: "26px", md: "39px", xl: "60px" }}
                fontWeight={500}
                lineHeight={"93%"}
              >
                <Text mr={{ base: "25px", md: "37px", xl: "35px" }}>
                  Get in touch with us
                </Text>
                <Image
                  mt={"10px"}
                  w={{ base: "15px", md: "25px", xl: "33px" }}
                  h={{ base: "15px", md: "25px", xl: "33px" }}
                  src={"/assets/arrow_two.svg"}
                />
              </Flex>
            </Link>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalMenu;
