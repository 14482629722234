import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";

const LayoutSection = ({
  id,
  children,
  opacity = ".8",
  title,
  colorText,
  withLine = true,
}) => {
  const imageSrc = useBreakpointValue({
    base: "/assets/line-sm.svg",
    md: "/assets/line-md.svg",
    xl: "/assets/line-xl.svg",
  });

  return (
    <Box py={{ base: "70px", md: "90px", xl: "100px" }}>
      <Flex
        id={id}
        w={"full"}
        justify={"space-between"}
        align={{ base: "center", xl: "start" }}
        fontSize={{ base: "24px", md: "28px", xl: "35px" }}
        fontWeight={300}
        lineHeight={"93%"}
        color={colorText}
        opacity={opacity}
      >
        <Text>/ {title}</Text>
        {withLine && (
          <Box>
            <Image src={imageSrc} alt={""} />
          </Box>
        )}
      </Flex>
      {children}
    </Box>
  );
};

export default LayoutSection;
