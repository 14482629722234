import { Box, Flex, Link, Text, VStack } from "@chakra-ui/react";
import PageContainer from "../PageContainer";
import Scroll from "react-scroll";

const linkStyle = {
  color: "purple.600",
  // borderBottom: "1px",
  // borderColor: "purple.600",
  _hover: { testStyle: "none" },
};

const Footer = () => {
  const scroll = Scroll.scroller;
  const toTag = (id) => {
    scroll.scrollTo(id, {
      smooth: true,
      duration: 1000,
    });
  };
  return (
    <Box
      py={{ base: "70px", md: "90px", xl: "100px" }}
      bgImage={{
        base: "/assets/footer/bg-sm.svg",
        md: "/assets/footer/bg-md.svg",
        xl: "/assets/footer/bg-xl.svg",
      }}
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
    >
      <PageContainer>
        <Text
          maxW={{ base: "205px", md: "400px" }}
          fontSize={{ base: "35px", md: "60px" }}
          fontWeight={500}
          lineHeight={"100%"}
        >
          Marly Group Limited
        </Text>
        <Flex
          w={"full"}
          mt={{ base: "40px", md: "45px", xl: "60px" }}
          wrap={"wrap"}
          fontSize={{ base: "24px", md: "30px" }}
          fontWeight={400}
          lineHeight={"93%"}
          direction={{ base: "column", xl: "row" }}
          justify={"space-between"}
        >
          <Box maxW={{ base: "334px", md: "582px", xl: "430px" }}>
            <Text>
              Take a look at our{" "}
              <Link {...linkStyle} onClick={() => toTag("current")}>
                current projects
              </Link>
              , learn{" "}
              <Link {...linkStyle} onClick={() => toTag("about")}>
                about us
              </Link>{" "}
              and{" "}
              <Link {...linkStyle} onClick={() => toTag("success")}>
                our success
              </Link>
              , check out our{" "}
              <Link {...linkStyle} onClick={() => toTag("events")}>
                international events
              </Link>{" "}
              and{" "}
              <Link {...linkStyle} onClick={() => toTag("expect")}>
                what you can expect
              </Link>
            </Text>
          </Box>
          <Flex
            maxW={{ base: "full", md: "534px", xl: "full" }}
            mt={{ base: "45px", md: "60px", xl: 0 }}
            fontSize={{ base: "14px", md: "24px", xl: "30px" }}
            fontWeight={400}
            lineHeight={"93%"}
            justify={"space-between"}
          >
            <Box mr={{ base: 0, xl: "110px" }}>
              <Text maxW={{ base: "181px", md: "372px" }}>
                2301 Bayfield Building 99 Hennessy Rd, Wan Chai Hong Kong
              </Text>
              <Text mt={"25px"} display={{ base: "none", xl: "block" }}>
                1-888-433-2115
              </Text>
            </Box>
            <VStack
              maxW={{ base: "114px", md: "216px", xl: "270px" }}
              spacing={{ base: "12px", md: "21px", xl: "17px" }}
              align={"start"}
            >
              <Link
                href={"mailto:hello@marlygroup.com"}
                _hover={{ textStyle: "none" }}
              >
                hello@marlygroup.com
              </Link>
              <Link _hover={{ textStyle: "none" }}>marlygroup.com</Link>
              <Text display={{ base: "block", xl: "none" }}>
                1-888-433-2115
              </Text>
            </VStack>
          </Flex>
        </Flex>
      </PageContainer>
    </Box>
  );
};

export default Footer;
