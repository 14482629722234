import { Container } from "@chakra-ui/react";

const PageContainer = ({ children }) => (
  <Container
    p={0}
    maxW={{ base: "container.sm", md: "container.md", xl: "container.xl" }}
  >
    {children}
  </Container>
);

export default PageContainer;
